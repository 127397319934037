export default {
  forms: {
    errors: {
      required: '{{label}} є обов`язковим полем',
      email: 'Введіть коректний email',
      mask: 'Невірно введений номер'
    }
  },
  errors: {
    4010: "Вибачте, але ця дія для вас недоступна",
    4030: "Ой, запис на цей час неможливий — ми працюємо лише в робочі години",
    4031: "Схоже, цей час уже заброньований. Оберіть інший, будь ласка!",
    4041: "На жаль, працівник не доступний у цей день. Давайте оберемо іншу дату?",
    4042: "У нас вже є клієнт із таким номером телефону. Можливо, це ви?",
    4043: "Ці послуги не активні або працівник не надає їх.",
    4060: "Перевищено ліміт безкоштовної версії. Оновіть підписку, щоб продовжити користування!",
    4070: "Оновлення даних цього запису неможливе. Дякуємо за розуміння!",
    4071: "Зміна часу неможлива для скасованих або завершених записів.",
    4072: "Запис створено на минулий час."
  }
}
