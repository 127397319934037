import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { CalendarActions } from './calendar-state.action';
import {
  ICalendarState,
  CALENDAR_STATE_TOKEN,
  IWorkingHoursEmployee,
  IEmployeeScheduleRes,
  IScheduleSlot,
  ICalendarAppointments
} from './calendar-state.interface';
import { Observable, tap, map, switchMap, forkJoin, catchError, throwError } from 'rxjs';
import { CalendarApiService } from '../services/calendar-api.service';
import { patch, updateItem } from '@ngxs/store/operators';
import { Helpers } from '../../../entities/helpers';
import { Employee } from '../../employees/state/employees-state.interface';
import { EmployeeApiService } from '../../employees/service/employees-api.service';
import { AppointmentsApiService } from '../../appointments/services/appointments-api.service';
import { DatePipe } from '@angular/common';
import { CalendarUtilityService } from '../../../entities/calendar-utility.service';
import { CalendarEmployee } from './calendar-state.interface';
import { format } from 'date-fns';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@State<ICalendarState>({
  name: CALENDAR_STATE_TOKEN,
  defaults: {
    scheduleSlots: [],
    appointments: [],
    calendarAppointments: [],
    clientAppointments: [],
    calendarEmployees: [],
    isLoading: false
  }
})
@Injectable()
export class CalendarState {
  constructor(
    private calendarApiService: CalendarApiService,
    private employeeApiService: EmployeeApiService,
    private appointmentsApiService: AppointmentsApiService,
    private calendarUtilityService: CalendarUtilityService,
    private datePipe: DatePipe,
    private store: Store,
    protected toaster: ToastrService,
    private translateService: TranslateService,
  ) { }

  @Action(CalendarActions.FetchEmployeeSchedule)
  fetchEmployeeSchedule(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.FetchEmployeeSchedule
  ): Observable<IEmployeeScheduleRes> {
    return this.calendarApiService.getEmployeeSchedule(action.params)
      .pipe(
        tap((res: IEmployeeScheduleRes) => {
          const transformedEvents: IScheduleSlot[] = res.data.map((slot: IWorkingHoursEmployee) => {
            const start = new Date(`${slot.date}T${slot.start_time}`);
            const end = new Date(`${slot.date}T${slot.end_time}`);
            let startTime = format(start, 'HH:mm');
            let endTime = format(end, 'HH:mm');

            return {
              title: `${startTime}-${endTime}`,
              start,
              end
            };
          });

          ctx.patchState({
            scheduleSlots: transformedEvents,
            isLoading: false
          });
        })
      );
  }

  @Action(CalendarActions.SetEmployeeSchedule)
  setEmployeeSchedule(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.SetEmployeeSchedule
  ): Observable<any> {
    const { schedule_id, data } = action.params;

    return this.calendarApiService.setEmployeeSchedule(schedule_id, data);
  }

  @Action(CalendarActions.DeleteEmployeeSchedule)
  deleteEmployeeSchedule(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.DeleteEmployeeSchedule
  ): Observable<any> {
    const { schedule_id, data } = action.params;

    return this.calendarApiService.deleteEmployeeSchedule(schedule_id, data)
      .pipe(tap((res) => {
        const state = ctx.getState();
        const newState = state.scheduleSlots.filter(slot => {
          const date = Helpers.formatDate(slot.start as Date);
          return !data.includes(date);
        });

        ctx.setState(
          patch({
            scheduleSlots: newState,
          })
        );
      }));
  }

  @Action(CalendarActions.SetClientAppointments)
  setClientAppointments(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.SetClientAppointments
  ): Observable<ICalendarAppointments[]> {
    return this.calendarApiService.getAppointments(action.params).pipe(
      tap((appointments: ICalendarAppointments[]) => {
        ctx.patchState({
          clientAppointments: [...appointments],
          isLoading: false
        });
      })
    );
  }

  @Action(CalendarActions.SetRecordsAppointments)
  setRecordsAppointments(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.SetRecordsAppointments
  ): Observable<ICalendarAppointments[]> {
    return this.calendarApiService.getAppointments(action.params).pipe(
      tap((appointments: ICalendarAppointments[]) => {
        ctx.patchState({
          appointments: [...appointments],
          isLoading: false
        });
      })
    );
  }

  @Action(CalendarActions.GetCalendarEmployees)
  getCalendarEmployees(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.GetCalendarEmployees
  ): Observable<CalendarEmployee[]> {
    const {day, limit, offset, bookable} = action.params;
    return this.employeeApiService.getEmployees({limit, offset, bookable})
      .pipe(
        switchMap((employees): Observable<CalendarEmployee[]> => {
          return forkJoin(
            employees.data.map((employee) =>
              this.calendarApiService.getEmployeeSchedule({
                schedule_id: employee.schedule_id,
                range_from: day,
                range_to: day
              }).pipe(
                map((schedule) => {
                  if (schedule.data.length === 0) {
                    return {
                      ...employee,
                      daySchedule: [],
                      scheduleTimePeriod: null
                    };
                  }
                  const scheduleTimeStart = schedule.data[0].start_time;
                  const scheduleTimeEnd = schedule.data[schedule.data.length - 1].end_time;
                  return {
                    ...employee,
                    daySchedule: schedule.data,
                    scheduleTimePeriod: {
                      start: scheduleTimeStart,
                      end: scheduleTimeEnd,
                    }
                  };
                })
              )
            )
          );
        }),
        tap((res: CalendarEmployee[]) => {
          ctx.patchState({
            calendarEmployees: [...res],
            isLoading: false
          });
        })
      );
  }

  @Action(CalendarActions.SetCalendarAppointments)
  setCalendarAppointments(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.SetCalendarAppointments
  ): Observable<ICalendarAppointments[]> {
    return this.calendarApiService.getAppointments(action.params).pipe(
      tap((appointments: ICalendarAppointments[]) => {
        const calendarApp = appointments.map((slot: ICalendarAppointments) => {
          return {
            ...slot,
            start: new Date(slot.start_at),
            end: new Date(slot.end_at),
            draggable: true,
            meta: {
              user: {...slot.employee}
            }
          };
        })
        ctx.patchState({
          calendarAppointments: [...calendarApp],
          isLoading: false
        });
      })
    );
  }

  @Action(CalendarActions.UpdateTimeCalendarAppointment)
  updateTimeCalendarAppointment(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.UpdateTimeCalendarAppointment
  ): Observable<ICalendarAppointments> {
    const date = new Date(action.params.start);
    const { employeeId } = action.params;
    const isoFormatDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

    return this.appointmentsApiService.updateAppointments(
      action.params.eventId,
      {
        start_at: isoFormatDate,
        ...(employeeId && { employee_id: employeeId })
      }
    ).pipe(
      tap((res) => {
        const appointment = this.calendarUtilityService.prepareCalendarAppointment(res);
        ctx.setState(
          patch<ICalendarState>({
            calendarAppointments: updateItem<any>(
              app => app.id === action.params.eventId,
              {
                ...appointment,
                start: new Date(appointment.start_at),
                end: new Date(appointment.end_at),
                draggable: true,
                meta: {
                  user: {...appointment.employee}
                }
              }
            )
          })
        );
      }),
      catchError((err) => {
        const errorMessage = this.translateService.instant('errors.' + err.error.detail.error_code);
        this.toaster.error(null, errorMessage);
        return throwError(err);
      }),
    );
  }

  // @Action(CalendarActions.UpdateUserCalendarAppointment)
  // updateUserCalendarAppointment(
  //   ctx: StateContext<ICalendarState>,
  //   action: CalendarActions.UpdateUserCalendarAppointment
  // ): Observable<ICalendarAppointments> {
  //   const {employeeId, eventId} = action.params;

  //   return this.appointmentsApiService.updateAppointments(
  //     eventId,
  //     {
  //       employee_id: employeeId
  //     }
  //   ).pipe(
  //     tap((res) => {
  //       const appointment = this.calendarUtilityService.prepareCalendarAppointment(res);
  //       ctx.setState(
  //         patch<ICalendarState>({
  //           calendarAppointments: updateItem<any>(
  //             app => app.id === eventId,
  //             {
  //               ...appointment,
  //               start: new Date(appointment.start_at),
  //               end: new Date(appointment.end_at),
  //               draggable: true,
  //               meta: {
  //                 user: {...appointment.employee}
  //               }
  //             }
  //           )
  //         })
  //       );
  //     })
  //   );
  // }

}
