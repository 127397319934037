import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Select } from "@ngxs/store";
import { CompanyStateSelectors } from "../../pages/company/state/company-state.selectors";
import { Observable } from "rxjs";
import { Branch } from "../../pages/company/state/company-state.interface";
import { environment } from '@env/environment';
import { Helpers } from "../../entities/helpers";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-link-follow-button',
  templateUrl: './link-follow-button.html',
  styleUrls: ['./link-follow-button.scss'],
  standalone: true,
  imports: [
    CommonModule
  ],
})
export class LinkFollowButton implements OnInit, OnDestroy {
  @Select(CompanyStateSelectors.allBranches) branches$: Observable<Branch[]>;

  @Input() branch_slug: string | null = null;

  widgetLink: string | null = null;

  private onDestroy$ = new Subject<void>();

  constructor(
    protected toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.branches$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((branches) => {
      const selectedBranch = this.branch_slug || this.getSingleBranchSlug(branches);
      if (selectedBranch) {
        this.widgetLink = `${environment.widgetEndpoint}/${selectedBranch}`;
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  navigateToWidget(): void {
    if (this.widgetLink) {
      window.open(this.widgetLink, '_blank');
    }
  }

  getSingleBranchSlug(branches: Branch[]): string | null {
    return branches.length === 1 ? branches[0].slug : null;
  }

  copyWidgetLink(): void {
     if (this.widgetLink) {
    Helpers.copyToClipboard(this.widgetLink);
    this.toastr.success(null, 'Посилання скопійовано');
    }
  }

}
